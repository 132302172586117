import styled from 'styled-components'

interface FlexProps {
  display?: string
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  alignItems?: 'inherit' | 'center' | 'flex-end' | 'flex-start' | 'stretch'
  justifyContent?:
    | 'inherit'
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
  flexWrap?:
    | 'nowrap'
    | 'wrap'
    | 'wrap-reverse'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset'
  gap?: number
  width?: string
}

interface CellProps {
  width?: string
}

export const Flex = styled.div<FlexProps>`
  display: ${(props) => props?.display || 'flex'};
  flex-wrap: ${(props) => props?.flexWrap || 'nowrap'};
  ${(props) => (props.flexDirection ? `flex-direction: ${props.flexDirection}` : '')};
  ${(props) => (props.alignItems ? `align-items: ${props.alignItems}` : '')};
  ${(props) => (props.justifyContent ? `justify-content: ${props.justifyContent}` : '')};
  ${(props) => (props.gap ? `gap: ${props.gap}px` : '')};
  ${(props) => (props.width ? `width: ${props.width}` : '')};
`

export const Cell = styled.div<CellProps>`
  width: ${(props) => props?.width || 'auto'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
